import { createTheme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotarySession from "./pages/NotarySession";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotarySigner from "./pages/NotarySigner";
import NotaryTagging from "./pages/NotaryTagging";

function App() {
  const theme = createTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              icon={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
          />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/notarization/:id" element={<NotarySession />} />
            <Route path="/signer/:id" element={<NotarySigner />} />
            <Route path="/templatecreation/:id" element={<NotaryTagging />} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
