import Grid from "@mui/material/Grid2";
import {fabric} from 'fabric';
import {
    AppBar,
    Box,
    Button,
    Card,
    Chip,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl, FormControlLabel, FormGroup, IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem, OutlinedInput,
    Pagination,
    Select, Switch,
    Toolbar,
    Typography
} from "@mui/material";
import notaryLogo from "../../Assets/images/notary_logo.png";
import React, {useEffect, useState} from "react";
import CircleIcon from '@mui/icons-material/Circle';
import ListItemIcon from "@mui/material/ListItemIcon";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PersonIcon from "@mui/icons-material/Person";
import {
    Badge,
    BusinessCenter,
    CalendarToday,
    CheckBox,
    DriveFileRenameOutline,
    Event,
    Gesture,
    Notes,
    Title,
    Verified
} from "@mui/icons-material";
import axios from "axios";
import {baseUrl} from "../../Utils/constant";
import {useLocation, useParams} from "react-router-dom";
import imageComplete from '../../Assets/images/completed-sign.png';
import {toast} from 'react-toastify';
import '../../App.css'
import Pusher from "pusher-js";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import {GlobalWorkerOptions} from "pdfjs-dist";
import workerSrc from 'pdfjs-dist/build/pdf.worker.js';
import {useTheme} from "@mui/styles";
import EditIcon from '@mui/icons-material/Edit';
import DrawIcon from '@mui/icons-material/Draw';
import CloseIcon from '@mui/icons-material/Close';

const PDFJS = window.pdfjsLib;
GlobalWorkerOptions.workerSrc = workerSrc;

const drawerWidth = 300;
const NotarySession = () => {
    const { id } = useParams();
    const location = useLocation();
    const theme = useTheme();
    const queryParams = new URLSearchParams(location.search);
    const jid = queryParams.get("jid");

    const [mainPdfJson, setMainPdfJson] = useState({});

    const [loading, setLoading] = useState(false);
    const [observer, setObserver] = useState(null);
    const [canvases, setCanvases] = useState([]);
    const [hasSelected, setHasSelected] = useState(false);
    const [activeTool, setActiveTool] = useState(null);
    const [toolGhosts, setToolGhosts] = useState([]);

    const [jobSchedule, setJobSchedule] = useState({});
    const [documents, setDocuments] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [notary, setNotary] = useState({});
    const [sessionId, setSessionId] = useState(null);

    const [pageCount, setPageCount] = useState(0);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [dialogComplete, setDialogComplete] = useState(false);
    const [dialogSeal, setDialogSeal] = useState(false);
    const [pdfPages, setPdfPages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [textEditing, setTextEditing] = useState(false);
    const [signerParticipants, setSignerParticipants] = useState([]);
    const [signMode, setSignMode] = useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        document.title = 'Notary Session';
        getSession()
        renderToolGhosts();
    }, []);

    useEffect(() => {
        if(selectedDocument) {
            getPDFPages(selectedDocument.file_path)
        }
    }, [selectedDocument]);

    useEffect(() => {
        if (pdfPages) {
            let tempCanvases = [];
            pdfPages.forEach((page, index) => {
                const canvas = document.getElementById(`canvasPage_${page.page}`);
                const initCanvas = new fabric.Canvas(canvas);
                initCanvas.setWidth(page.width);
                initCanvas.setHeight(page.height);

                fabric.Image.fromObject({src: page.img}, function (img) {
                    initCanvas.setBackgroundImage(img, initCanvas.renderAll.bind(initCanvas), {
                        scaleX: initCanvas.width / img.width,
                        scaleY: initCanvas.height / img.height
                    });
                });


                initCanvas.selection = false;

                if (mainPdfJson) {
                    renderObject(initCanvas, index, mainPdfJson);
                }

                window.addEventListener('keydown', (e) => {
                    if (e.key === 'Delete') {
                        const activeObject = initCanvas.getActiveObjects()
                        if (activeObject) {
                            initCanvas.remove(...activeObject);
                        }
                        initCanvas.discardActiveObject();
                    }
                });
                tempCanvases.push(initCanvas);
            });
            setCanvases(tempCanvases);
        }
    }, [pdfPages]);



    useEffect(() => {
        if (sessionId) {
            const pusher = new Pusher('8832ca315ec10869b1e6', {
                cluster: 'ap1'
            });

            let channel = pusher.subscribe(`notary-session-${sessionId}`);
            channel.bind('updateSignerMetadata', function(data) {
                axios({
                    method: 'get',
                    url: `${baseUrl}/findNotarySessionLink/${id}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    setMainPdfJson(JSON.parse(res.data.session[0].metadata));
                    const metadata = JSON.parse(res.data.session[0].metadata);
                    if (metadata.hasOwnProperty(`doc_${selectedDocument.ID}`)) {
                        let currDocMeta = metadata[`doc_${selectedDocument.ID}`];
                        canvases.forEach((canvas, index) => {
                            const tempMetaData = canvas.toJSON(
                                ['type', 'left', 'top', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'backgroundColor', 'autoWidth', 'lockRotation', 'lockScalingX', 'hasRotatingPoint', 'metadata']
                            );
                            tempMetaData.objects = currDocMeta[index];
                            canvas.loadFromJSON(tempMetaData, () => {
                                canvas.renderAll();
                            });
                        });
                    }
                });
            });

            return () => {
                pusher.unsubscribe(`notary-session-${sessionId}`);
            }
        }
    }, [sessionId, selectedDocument, canvases]);

    useEffect(() => {
        canvases && canvases.forEach((canvas) => {
            let objects = canvas.getObjects();
            objects.forEach((object) => {
                if(signMode) {
                    object.hoverCursor = 'pointer';
                } else {
                    object.hoverCursor = 'default';
                }
            });

            canvas.off('object:added');
            canvas.off('object:modified');
            canvas.off('object:removed');
            canvas.off('selection:created');
            canvas.off('selection:cleared');
            canvas.off('selection:updated');

            canvas.on('object:modified', (e) => {
                getMetaData();
            });

            canvas.on('selection:created', () => {
                setHasSelected(true);
                const selectedObject = canvas.getActiveObject();
                if (selectedObject.metadata && selectedObject.metadata.type === 'notary' && signMode) {
                    notaryObjectReplacer(canvas, selectedObject);
                }
                getMetaData()
            });

            //selection change
            canvas.on('selection:updated', () => {
                const selectedObject = canvas.getActiveObject();
                if (selectedObject.metadata && selectedObject.metadata.type === 'notary' && signMode) {
                    notaryObjectReplacer(canvas, selectedObject);
                }
                getMetaData()
            });

            canvas.on('selection:cleared', () => {
                setHasSelected(false);
                getMetaData()
            });
        });

        if (canvases) {
            try {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setCurrentPage(parseInt(entry.target.id.split('_')[1]));
                        }
                    });
                }, {threshold: 0.6});

                pdfPages.forEach((page, index) => {
                    observer.observe(document.getElementById(`observer_${page.page}`));
                });

                setObserver(observer);
            } catch (e) {
                console.log(e);
            }
        }
    }, [canvases, signMode]);

    useEffect(() => {
        if (selectedDocument) {
            window.addEventListener('keydown', handleHotKeys);
        }

        return () => {
            window.removeEventListener('keydown', handleHotKeys);
        }
    },[selectedDocument, canvases, activeTool, textEditing]);

    const getSession = () => {
        axios({
            method: 'get',
            url: `${baseUrl}/findNotarySessionLink/${id}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setDocuments(res.data.sessionDoc);
            setJobSchedule(res.data.jobSchedule);
            setParticipants(res.data.job_participant);
            setNotary(res.data.user[0]);
            setSessionId(res.data.session[0].id);
            setMainPdfJson(JSON.parse(res.data.session[0].metadata));
        });
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSignerParticipants(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleHotKeys = (e) => {
        switch (e.key) {
            case 't':
                setTool('Notary Text', canvases, 'notary_text', 'text');
                break;
            case 'a':
                setTool('Notary Name', canvases, 'notary_name', 'text');
                break;
            case 'n':
                setTool('Commission ID', canvases, 'notary_commission_id', 'text');
                break;
            case 'e':
                setTool('Notary Seal', canvases, 'notary_seal', 'placeholder');
                break;
            case 'o':
                setTool('Disclosure', canvases, 'notary_disclosure','text');
                break;
            case 's':
                setTool('Notary Signature', canvases, 'notary_signature', 'placeholder', 50, 100);
                break;
            case 'x':
                setTool('Commission Exp', canvases, 'notary_commission_exp','text');
                break;
            case 'd':
                setTool('Notary Date', canvases, 'notary_date', 'text');
                break;
            case 'w':
                setActiveTool('whiteout');
                break;
            case 'Escape':
                setActiveTool(null);
                canvases.forEach((canvas) => {
                    canvas.defaultCursor = 'default';
                });
                break;
        }
    }

    const setMouseDown = (activeTool) => {
        canvases.forEach((canvas) => {
            canvas.off('mouse:down');
        });
        canvases.forEach((canvas) => {
            canvas.on('mouse:down', (event) => {
                switch (activeTool) {
                    case 'notary_text':
                        addTextNotary(event.e, 'Notary Text', activeTool);
                        break;
                    case 'notary_name':
                        addTextNotary(event.e, 'Notary Name', activeTool);
                        break;
                    case 'notary_commission_id':
                        addTextNotary(event.e, 'Commission ID', activeTool);
                        break;
                    case 'notary_commission_exp':
                        addTextNotary(event.e, 'Commission Exp', activeTool);
                        break;
                    case 'notary_seal':
                        addPlaceholderNotary(event.e, 'Notary Seal', 90, 90, activeTool);
                        break;
                    case 'notary_disclosure':
                        addTextNotary(event.e, 'Disclosure',activeTool);
                        break;
                    case 'notary_signature':
                        addPlaceholderNotary(event.e, 'Notary Signature', 50, 100,activeTool);
                        break;
                    case 'notary_date':
                        addTextNotary(event.e, 'Notary Date', activeTool);
                        break;
                    case 'notary_whiteout':
                        addWhiteOutNotary(event.e);
                        break;
                }
                setActiveTool(null);
                canvases.forEach((canvas) => {
                    canvas.defaultCursor = 'default';
                    canvas.off('mouse:down');
                });
                getMetaData();
            });
        });
    }

    const renderToolGhosts = () => {
        let ghosts = [];

        let tools = [
            {text: 'Notary Text', type: 'text', field : 'notary_text'},
            {text: 'Notary Name', type: 'text', field : 'notary_name'},
            {text: 'Commission ID', type: 'text', field : 'commission_id'},
            {text: 'Commission Exp', type: 'text', field : 'commission_exp'},
            {text: 'Notary Title', type: 'text', field : 'notary_title'},
            {text: 'Notary Seal', type: 'placeholder', field : 'seal', height : 90, width : 90},
            {text: 'Notary Disclosure', type: 'text', field : 'disclosure'},
            {text: 'Notary Signature', type: 'placeholder', field : 'signature', height : 50, width : 100},
            {text: 'Notary Date', type: 'text', field : 'date'},
            {text: 'Notary Initials', type: 'placeholder', field : 'notary_initials', height : 70, width : 70},
            {text: '✓', type: 'text', field : 'checkbox'},
        ];

        tools.forEach((tool) => {
            const image = new Image();
            if (tool.type === 'text') {
                image.src = getToolGhost(tool.text, tool.type).toDataURL('image/png');
                ghosts.push({
                    type: tool.type,
                    image: image,
                    field: tool.field,
                });
            } else{
                image.src = getToolGhost(tool.text, tool.type, tool.height, tool.width).toDataURL('image/png');
                ghosts.push({
                    type: tool.type,
                    image: image,
                    field: tool.field,
                    height: tool.height,
                    width: tool.width,
                });
            }
        });

        setToolGhosts(ghosts);
    }

    const getToolGhost = (text, type, height = null, width = null) => {
        let object = null;
        switch (type) {
            case 'text' :
                object = new fabric.Text(text, {
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    backgroundColor: '#f1c40f',
                });
                break;
            case 'placeholder' :
                const rect = new fabric.Rect({
                    height : height ?? 90,
                    width : width ?? 90,
                    fill: '#f1c40f',
                });
                const txt = new fabric.Text(text, {
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                });
                txt.left = rect.left + (rect.width - txt.width) / 2;
                txt.top = rect.top + (rect.height - txt.height) / 2;
                object = new fabric.Group([rect, txt], {
                    lockScalingX: true,
                    lockScalingY: true
                });
                break;
        }

        return object;
    }

    const setTool = (text, canvases, tool, type, height = null, width = null) => {
        if (!hasSelected) {
            canvases.forEach((canvas) => {
                canvas.defaultCursor = `url("${getToolGhost(text, type, height, width).toDataURL()}") 0 0, auto`;
            });
            setActiveTool(tool);
            setMouseDown(tool);
        }
    }

    const renderObject = (initCanvas, index, json) => {
        if (json.hasOwnProperty(`doc_${selectedDocument.ID}`)) {
            const objects = json[`doc_${selectedDocument.ID}`][index];
            objects && objects.forEach((object) => {
                fabric.util.enlivenObjects([object], (enlivenedObjects) => {
                    enlivenedObjects.forEach((obj) => {
                        initCanvas.add(obj);
                    });
                });
            });
        }
    }

    const notaryObjectReplacer = (canvas, object) => {
        switch (object.metadata.tool) {
            case 'notary_text':
                if (object.text === 'Notary Text') {
                    object.text = '';
                    object.enterEditing();
                    setTextEditing(true);
                    setActiveTool(null);
                    object.on('editing:exited', (e) => {
                        object.backgroundColor = '';
                        setTextEditing(false);
                        setActiveTool(null);

                        if (object.text === '') {
                            canvas.remove(object);
                        }
                    });
                }
                break;
            case 'notary_name':
                object.text = notary.fullname;
                break;
            case 'notary_commission_id':
                object.text = notary.comm_id_num;
                break;
            case 'notary_commission_exp':
                object.text = notary.comm_expdate;
                break;
            case 'notary_disclosure':
                object.text = notary.disclosure;
                break;
            case 'notary_date':
                object.text = new Date().toLocaleDateString();
                break;
            case 'notary_signature':
                imageReplacer(notary.signature_filename, canvas, object);
                break;
            case 'notary_seal':
                openDialogSeal();
                break;
            case 'notary_initials':
                imageReplacer(notary.initials_filename, canvas, object);
                break;
        }
        object.metadata.status = 'signed';
        object.hasRotatingPoint = false;
        object.lockScalingX = false;
        object.lockScalingY = false;
        object.backgroundColor = '';
    }

    const imageReplacer = (image_url, canvas, object) => {
        const img = new Image();
        img.src = image_url;
        img.onload = function () {
            const fabricImg = new fabric.Image(img, {
                left: object.left,
                top: object.top,
                lockRotation: true,
                metadata: {
                    type: 'notary',
                    status : 'signed',
                    uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
                }
            });

            fabricImg.scaleToWidth(100)
            canvas.remove(object);
            canvas.add(fabricImg);
        }
    }

    const getPDFPages = (url) => {
        destroyAllInstance();

        setLoading(true);
        setCurrentPage(1)
        setPdfPages([])
        window.scrollTo(0, 0);

        PDFJS.getDocument(url).promise.then((pdf) => {
            setPageCount(pdf.numPages);
            let tempPages = [];
            new Promise((resolve, reject) => {
                for (let i = 1; i <= pdf.numPages; i++) {
                    pdf.getPage(i).then(async (page) => {
                        const scale = 1;
                        const viewport = page.getViewport({scale: scale});
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };

                        await page.render(renderContext).promise;
                        let img = canvas.toDataURL('image/png');

                        tempPages.push({page: i, img: img, height : viewport.height, width : viewport.width});
                        tempPages.sort((a, b) => {
                            return a.page - b.page;
                        });

                        setTimeout(() => {
                            resolve(img)
                        }, 100)
                    });
                }
            }).then((response) => {
                setPdfPages(tempPages);
            }).finally(() => {
                setLoading(false);
            });
        });
    }

    const destroyAllInstance = () => {
        canvases && canvases.forEach((canvas) => {
            canvas.dispose();
        });

        observer && observer.disconnect();
    }

    const addText = (e, participant, text, type = null) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const textObject = new fabric.IText(text, {
            left: canvasPosition.x,
            top: canvasPosition.y,
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            backgroundColor: participant.tag_color + '80',
            autoWidth: true,
            lockRotation: true,
            lockScalingX: true,
            hasRotatingPoint: false,
            metadata : {
                type: type ? type : 'participant',
                id : participant.ID,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        canvas.add(textObject);

        getMetaData();
    }

    const addPlaceholder = (e, participant, text_description, height = 40, width = 150, type) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const rect = new fabric.Rect({
            height : height,
            width : width,
            fill: participant.tag_color + '80',
            metadata: {
                type: type,
                id : participant.ID,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        const text = new fabric.IText(text_description, {
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            metadata: {
                type: type,
                id : participant.ID,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        text.left = rect.left + (rect.width - text.width) / 2;
        text.top = rect.top + (rect.height - text.height) / 2;

        const group = new fabric.Group([rect, text], {
            left: canvasPosition.x,
            top: canvasPosition.y,
            lockScalingX: true,
            lockScalingY: true,
            metadata: {
                type: type,
                id : participant.ID,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        canvas.add(group);

        getMetaData()
    }

    const addTextNotary = (e, text, tool) => {
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const textObject = new fabric.IText(text, {
            left: canvasPosition.x,
            top: canvasPosition.y,
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            backgroundColor: '#f1c40f',
            autoWidth: true,
            lockRotation: true,
            lockScalingX: true,
            hasRotatingPoint: false,
            metadata: {
                type: 'notary',
                tool : tool,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        canvas.add(textObject);

        getMetaData();
    }

    const addPlaceholderNotary = (e, text_description, height = 40, width = 150, tool) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const rect = new fabric.Rect({
            height : height,
            width : width,
            fill: '#f1c40f',
            metadata: {
                type: 'notary',
                status : 'pending',
                tool : tool,
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        const text = new fabric.IText(text_description, {
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            metadata: {
                type: 'notary',
                status : 'pending',
                tool : tool,
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        text.left = rect.left + (rect.width - text.width) / 2;
        text.top = rect.top + (rect.height - text.height) / 2;

        const group = new fabric.Group([rect, text], {
            left: canvasPosition.x,
            top: canvasPosition.y,
            lockScalingX: true,
            lockScalingY: true,
            metadata: {
                type: 'notary',
                status : 'pending',
                tool : tool,
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        canvas.add(group);
        getMetaData();
    }

    const addWhiteOutNotary = (e) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        //add only a resizable rectangle color white
        const rect = new fabric.Rect({
            left: canvasPosition.x,
            top: canvasPosition.y,
            fill: 'white',
            width: 100,
            height: 100,
            lockRotation: true,
            hasRotatingPoint: false,
        });

        canvas.add(rect);
        getMetaData();
    }

    const openDialogComplete = () => {

        setDialogComplete(true);
    }

    const renderPDF = async (pdfDoc) => {
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

        await Promise.all(canvases.map(async (canvas, index) => {
                const objects = canvas.getObjects();
                const page = pdfDoc.getPage(index);
                const {width, height} = page.getSize();

                await Promise.all(objects.map(async (object, objIndex) => {
                    const x = object.left;
                    const y = (height - 12) - object.top;

                    async function addObjects() {
                        if (object.text) {
                            const fontSize = object.fontSize;
                            const text = object.text;
                            page.drawText(text, {
                                x: x,
                                y: y,
                                size: fontSize,
                                font: font,
                            });
                        }

                        if (object.type === 'image') {
                            await embedImage(pdfDoc, object, page, height, width);
                        }
                    }

                    await addObjects();
                }))
            })
        )

        return true;
    }

    const embedImage = async (pdfDoc, object, page, height, width) => {
       return new Promise((resolve, reject) => {
            try {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.src = object.src + '?t=' + new Date().getTime();
                img.onload = async () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const imgData = canvas.toDataURL('image/png');


                    await pdfDoc.embedPng(imgData).then((image) => {
                        const dimensions = object.getBoundingRect();
                        const width = dimensions.width;
                        const height = dimensions.height;

                        const pageDim = page.getSize();
                        const pageHeight = pageDim.height;

                        const x = dimensions.left;
                        const y = pageHeight - dimensions.top - height;

                        page.drawImage(image, {
                            x: x,
                            y: y,
                            width: width,
                            height: height,
                        });
                        resolve(true)
                    });
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    const completeSession = async () => {
        //check if all objects on all canvas is signed
        let withPendingPages = []
        let isSigned = true;
        canvases.forEach((canvas) => {
            const objects = canvas.getObjects();
            objects.forEach((object) => {
                if (object.metadata.status !== 'signed') {
                    isSigned = false;
                }
            });
        });

        //get pages with pending statuses
        canvases.forEach((canvas, index) => {
            const objects = canvas.getObjects();
            let withPending = false;
            objects.forEach((object) => {
                if (object.metadata.status !== 'signed') {
                    withPending = true;
                }
            });
            if (withPending) {
                withPendingPages.push(index + 1);
            }
        });

        if (!isSigned) {
            toast.error(`Please complete all fields on page(s) ${withPendingPages.join(', ')}`);
            setDialogComplete(false)
            return;
        }


       setUploading(true);
       setTimeout(async () => {
           const existingPdfBytes = await fetch(selectedDocument.file_path)
               .then((res) => res.arrayBuffer());
           let pdfDoc = await PDFDocument.load(existingPdfBytes);

           if (await renderPDF(pdfDoc)) {
               const pdfBytes = await pdfDoc.save();
               const pdfBlob = new Blob([pdfBytes], {type: 'application/pdf'});
               const pdfDataUri = await pdfDoc.saveAsBase64({dataUri: true});

               // for testing purposes only to download pdf immediately
               // const link = document.createElement('a');
               // link.href = URL.createObjectURL(pdfBlob);
               // link.download = selectedDocument.file_name;
               // link.click();
               // return

               const finalUrl = pdfDataUri.split(',');
               const payload = {
                   doc_base64: finalUrl[1],
                   job_id: selectedDocument.job_id,
                   job_doc_id: selectedDocument.ID,
                   status: 'COMPLETED',
                   docId: selectedDocument.ID,
               };

               try {
                   const res = await axios.patch(
                       `${baseUrl}/job-documents/${selectedDocument.job_id}`,
                       payload,
                       {headers: {'Content-Type': 'application/json'}}
                   );

                   if (res.status === 200) {
                       toast.success('Document Completed!');
                       selectedDocument.status = 'COMPLETED';
                   } else {
                       toast.error('Error in completing document!');
                   }

                   setDialogComplete(false);
               } catch (error) {
                   console.error('Error in Axios request:', error);
               } finally {
                   setUploading(false);
               }
           }
       }, 2000)

    }

    function endSession() {
        const payload = {
            metadata: JSON.stringify(mainPdfJson),
            status: 'END NOTARIZATION',
            jobId: Number(documents[0].jobDocument.job_id),
            sessionId : sessionId
        };
        axios({
            method: 'patch',
            url: `${baseUrl}/start-end/${Number(documents[0].jobDocument.job_id)}`,
            data: payload,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
           toast.success('Session Ended!');
            window.location.href = `https://dctverify.com/job-view.php?jid=${encodeURIComponent(jid)}`;
        }).catch((error) => {
        });
    }

    const jumpToPage = (page) => {
        const canvas = document.getElementById(`canvasPage_${page}`);
        canvas.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    const syncPage = () => {
        axios({
            method: 'post',
            url: `${baseUrl}/trigger-session/${sessionId}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data : {
                documentId : selectedDocument.ID,
                page : currentPage,
                event : 'syncPage'
            }
        })
    }

    const getMetaData = () => {
        let tempJson = {};
        canvases.forEach((canvas, index) => {
            tempJson[index] = canvas.toJSON(
                ['type', 'left', 'top', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'backgroundColor', 'autoWidth', 'lockRotation', 'lockScalingX', 'hasRotatingPoint', 'metadata']
            ).objects;
        });

        if (!mainPdfJson) {
            let tempMainJson = {};
            documents.forEach((doc) => {
                tempMainJson[`doc_${doc.jobDocument.ID}`] = [];
            })
            tempMainJson[`doc_${selectedDocument.ID}`] = tempJson;
            setMainPdfJson(tempMainJson);
            updateMetaData(tempMainJson);
        } else {
            let tempMainJson = mainPdfJson;
            tempMainJson[`doc_${selectedDocument.ID}`] = tempJson;
            setMainPdfJson(tempMainJson);
            updateMetaData(tempMainJson);
        }

    }

    const updateMetaData = (metadata) => {
        axios({
            method: 'post',
            url: `${baseUrl}/update-notary-metadata/${sessionId}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                metadata: JSON.stringify(metadata),
            }
        }).then((res) => {
        });
    }


    const dragStartSetCursorGhost = (e, field) => {
         let ghost = toolGhosts.find((tool) => tool.field === field);
         e.dataTransfer.setDragImage(ghost.image, 0, 0);
    }

    function getStyles(name, personName, theme) {
        return {
            fontWeight: personName.includes(name)
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
        };
    }

    function openDialogSeal ()  {
        setDialogSeal(true);
    }

    function executeSeal() {
        const canvas = canvases[currentPage - 1];
        const object = canvas.getActiveObject();
        imageReplacer(notary.seal, canvas, object);
        setDialogSeal(false);
        setSignerParticipants([]);

        toast.success('Seal Added!');
    }

    const sessionMenu = (
        <div>
            <Toolbar fi>
                <img
                    src={notaryLogo}
                    alt="image"
                    width="150"
                    height="32"
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                />
            </Toolbar>
            <Divider />

            <Grid container>
                <FormControl sx={{ m: 2 }} fullWidth size="small">
                    <InputLabel id="document-label">Select a Document</InputLabel>
                    <Select
                        labelId="document-label"
                        id="document-label"
                        label="Select a Document"
                        variant="outlined"
                        value={selectedDocument === null ? '' : selectedDocument}
                        onChange={(e) => setSelectedDocument(e.target.value)}
                    >
                        {documents.map((doc) => (
                            <MenuItem value={doc.jobDocument} key={doc.id}>
                                {doc.jobDocument.file_name}
                            </MenuItem>
                        ))}
                    </Select>

                    <Button variant='outlined' sx={{mt : 3}} onClick={() => syncPage()} disabled={!selectedDocument}>
                        Sync Page
                    </Button>
                </FormControl>
                <Box sx={{ p : 2 }}>
                    {jobSchedule.length ? (
                        jobSchedule.map((schedule, index) => (
                            <div key={index}>
                                <iframe
                                    src={schedule.whereby_host_link + '&logo=off&background=off'}
                                    title="Whereby Session"
                                    width="100%"
                                    height="550px"  // Adjust this according to your sidebar height
                                    style={{ border: 'none', borderRadius: '10px' }}
                                    allow="camera; microphone; fullscreen"
                                />
                            </div>
                        ))
                    ) : (
                        <p>Loading video...</p>
                    )}
                </Box>
            </Grid>
        </div>
    )

    const participantView = (
        <div>
            {participants.map((participant, index) => (
                <Box sx={{px : 2, pb : 2}} key={index}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <CircleIcon fontSize="small" sx={{ color: participant.tag_color, mr: 0.5 }}/>
                        <Typography variant="subtitle2">
                            {participant.fullname}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt : 2}}>
                        <Chip label="Text" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={(e) => addText(e, participant, 'Participant Text', 'participant_text')}/>
                        <Chip label="Name" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={(e) => addText(e, participant, 'Participant Name', 'participant_name')}/>
                        <Chip label="Sign" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={  (e) => addPlaceholder(e, participant, 'Participant Signature', 40, 150, 'participant_signature')}/>
                        <Chip label="Initial" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={  (e) => addPlaceholder(e, participant, 'Participant Initials', 80, 80, 'participant_initials')}/>
                    </Box>
                </Box>
            ))}
            <Divider/>
        </div>
    )

    const notaryView = (
        <div>
            <Dialog open={dialogComplete}
                onClose={() => setDialogComplete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Complete Session"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to complete this session?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr : 3, pb : 3 }}>
                    <Button color="error" onClick={() => setDialogComplete(false)}>Cancel</Button>
                    <Button color="success" onClick={() => completeSession()} disabled={uploading}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogSeal}
                    onClose={() => setDialogSeal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Seal Details"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setDialogSeal(false)}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid container sx={{pt : 1}}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="seal-label">Seal Type</InputLabel>
                            <Select
                                labelId="seal-label"
                                id="seal-label"
                                label="Seal Type"
                                variant="outlined">
                                <MenuItem value={1}>Acknowledgement</MenuItem>
                                <MenuItem value={2}>JURAT</MenuItem>
                                <MenuItem value={3}>Copy Certification</MenuItem>
                                <MenuItem value={4}>Verification of Fact</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth size="small" sx={{mt : 3}}>
                            <InputLabel id="signer-label">Select Signers</InputLabel>
                            <Select
                                labelId="signer-label"
                                id="signer"
                                multiple
                                variant="outlined"
                                value={signerParticipants}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Signers" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {participants?.map((participant) => (
                                    <MenuItem
                                        key={participant.id}
                                        value={participant.fullname}
                                        style={getStyles(participant.fullname, signerParticipants, theme)}
                                    >
                                        {participant.fullname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ pr : 3, pb : 3 }}>
                    <Button color="error" onClick={() => setDialogSeal(false)}>Cancel</Button>
                    <Button color="success" onClick={() => executeSeal()}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{px : 2}}>
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <CircleIcon fontSize="small" sx={{ color: 'red', mr: 0.5 }}/>
                    <Typography variant="subtitle2">
                        {notary.fullname}
                    </Typography>
                </Box>
            </Box>
            <List component="nav" dense>
                <ListItem secondaryAction={
                    <Switch defaultChecked size="small"
                            sx={{pl : -2}}
                            onChange={(e) => setSignMode(e.target.checked)}
                            checked={signMode} />}>
                    <ListItemIcon>
                        { signMode ? <DrawIcon/> : <EditIcon/> }
                    </ListItemIcon>
                    <ListItemText primary={signMode ? 'Sign Mode' : 'Edit Mode'} />
                </ListItem>

                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Text', 'text')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'notary_text')}
                    secondaryAction={
                        <Chip variant={ activeTool === 'text' ? 'filled' : 'outlined' }
                              size="small" label="T" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <Title />
                    </ListItemIcon>
                    <ListItemText primary="Text" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Name', 'name')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'notary_name')}
                    secondaryAction={
                        <Chip variant={ activeTool === 'name' ? 'filled' : 'outlined' }
                              size="small" label="A" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Name" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Title', 'title')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'notary_title')}>
                    <ListItemIcon>
                        <BusinessCenter/>
                    </ListItemIcon>
                    <ListItemText primary="Title" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Commission ID', 'commission_id')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'commission_id')}
                          secondaryAction={
                        <Chip variant={ activeTool === 'commission_id' ? 'filled' : 'outlined' }
                              size="small" label="N" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <Badge/>
                    </ListItemIcon>
                    <ListItemText primary="Commision ID"  />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Commission Exp', 'commission_exp')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'commission_exp')}
                    secondaryAction={
                        <Chip variant={ activeTool === 'commission_exp' ? 'filled' : 'outlined' }
                              size="small" label="X" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <Event/>
                    </ListItemIcon>
                    <ListItemText primary="Commision Exp Date" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addPlaceholderNotary(e, 'Notary Seal', 90, 90, 'seal')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'seal')}
                          secondaryAction={
                        <Chip variant={ activeTool === 'seal' ? 'filled' : 'outlined' }
                              size="small" label="E" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <Verified/>
                    </ListItemIcon>
                    <ListItemText primary="Seal" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Disclosure', 'disclosure')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'disclosure')}
                    secondaryAction={
                        <Chip variant={ activeTool === 'disclosure' ? 'filled' : 'outlined' }
                              size="small" label="O" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <Notes/>
                    </ListItemIcon>
                    <ListItemText primary="Disclosure" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addPlaceholderNotary(e, 'Notary Signature', 50, 100, 'signature')}
                    onDragStart={(e) => dragStartSetCursorGhost(e,'signature')}
                    secondaryAction={
                        <Chip variant={ activeTool === 'signature' ? 'filled' : 'outlined' }
                              size="small" label="S" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <Gesture/>
                    </ListItemIcon>
                    <ListItemText primary="Signature" />
                </ListItem>
                <ListItem draggable={true}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'notary_initials')}
                          onDragEnd={(e) => addPlaceholderNotary(e, 'Notary Initials', 70, 70, 'initials')}>
                    <ListItemIcon>
                        <TextFieldsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Initial" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Date')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'date', 'date')}
                    secondaryAction={
                        <Chip variant={ activeTool === 'date' ? 'filled' : 'outlined' }
                              size="small" label="D" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <CalendarToday/>
                    </ListItemIcon>
                    <ListItemText primary="Date" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, '✓', 'checkbox')}
                          onDragStart={(e) => dragStartSetCursorGhost(e,'checkbox')}>
                    <ListItemIcon>
                        <CheckBox/>
                    </ListItemIcon>
                    <ListItemText primary="Checkbox" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addWhiteOutNotary(e)}
                    secondaryAction={
                        <Chip variant={ activeTool === 'whiteout' ? 'filled' : 'outlined' }
                              size="small" label="W" sx={{borderRadius : 1}}/>
                    }>
                    <ListItemIcon>
                        <DriveFileRenameOutline/>
                    </ListItemIcon>
                    <ListItemText primary="White Out" />
                </ListItem>
            </List>
        </div>
    )

    const toolsMenu = (
        <div>
            <Box>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ m: 2 }}>
                    Participants
                </Typography>
            </Box>

            {participantView}

            <Box sx={{ m: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                    Notary
                </Typography>
            </Box>

            {notaryView}
        </div>
    )

    return (
        <Box sx={{ display: 'flex', touchAction : 'pan-y' }}>
            <CssBaseline />
            <AppBar position="fixed" elevation={0} sx={{
                    width: { sm: `calc(100% - ${drawerWidth * (selectedDocument ? 2 : 1)}px)` },
                    bgcolor: 'background.paper',
                    ml: { sm: `${drawerWidth}px` },
                    mr: { sm: `${selectedDocument ? drawerWidth : 0 }px` },
                }}>
                <Toolbar sx={{ justifyContent : "space-between" }}>
                    <Button variant="outlined" color="error" onClick={() => endSession()}>
                        End Session
                    </Button>

                    {
                        pageCount > 0 && (
                            <Pagination
                                count={pageCount}
                                onChange={(e, page) => jumpToPage(page)}
                                page={currentPage}
                                color="primary"
                                size="small"
                                shape="rounded"
                            />
                        )
                    }

                    <Button variant="contained" color="success" onClick={() => openDialogComplete()}
                            disabled={selectedDocument?.status === 'COMPLETED' || !selectedDocument}>
                        Complete
                    </Button>
                </Toolbar>
                <Divider/>
            </AppBar>


            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open>
                    {sessionMenu}
                </Drawer>
            </Box>


            {!selectedDocument && (
                <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : 1, bgcolor : '#f0f0f0', pl: {  sm: `${drawerWidth}px` },}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Select a document to start
                        </Typography>
                    </Box>
                </Box>
            )}

            {loading  && (
                <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : 2, bgcolor : '#f0f0f0'}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center'}}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Loading Document...
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : -10, bgcolor : '#f0f0f0'}}>
            </Box>

            {selectedDocument?.status === 'COMPLETED' && (
                <Box sx={{ position : 'fixed', width : '100%', height : '100%', zIndex : 2, bgcolor : 'rgba(197,255,190,0.59)'}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center'}}>
                            <img src={imageComplete} alt="Complete Sign"
                                 style={{
                                     position: 'absolute',
                                     top: '50%',
                                     left: '50%',
                                     transform: 'translate(-50%, -50%)',
                                     width: 300,
                                     height: 300,
                                     pointerEvents: 'none'
                                 }}/>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box sx={{
                pt: '100px',
                pb: '64px',
                width: '100%',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#f0f0f0'
            }}>

            <Grid container id="canvasPages">
                    {!loading && selectedDocument && (pdfPages.map((page, index) => (
                        //vertical scrolling only of pages
                        <Grid className="pages-container" id={`observer_${page.page}`} xs={12} key={index} sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', width : '100%', mb : 3}}>
                            <Chip key={index} label={`Page ${page.page}`} sx={{ mb : 2}} size="small"/>
                            <Card elevation={8} sx={{ borderRadius : 0 }}>
                                <canvas id={`canvasPage_${page.page}`} className="canvasObject"/>
                            </Card>
                        </Grid>
                    )))}
                </Grid>
            </Box>

            <Drawer sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                open={!(!selectedDocument)}
                anchor="right"
            >
                {toolsMenu}
            </Drawer>
        </Box>
    );
}

export default NotarySession;
