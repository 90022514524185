import Grid from "@mui/material/Grid2";
import {fabric} from 'fabric';
import {
    AppBar,
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    InputLabel,
    List, ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Pagination,
    Select, Switch,
    Toolbar,
    Typography
} from "@mui/material";
import notaryLogo from "../../Assets/images/notary_logo.png";
import React, {useEffect, useState} from "react";
import CircleIcon from '@mui/icons-material/Circle';
import ListItemIcon from "@mui/material/ListItemIcon";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PersonIcon from "@mui/icons-material/Person";
import {
    Badge,
    BusinessCenter,
    CalendarToday,
    CheckBox, DriveFileRenameOutline,
    Event,
    Gesture,
    Notes,
    Title,
    Verified
} from "@mui/icons-material";
import axios from "axios";
import {baseUrl} from "../../Utils/constant";
import {useLocation, useParams} from "react-router-dom";
import {FaEraser} from "react-icons/fa";
import imageComplete from '../../Assets/images/completed-sign.png';
import {toast} from 'react-toastify';
import '../../App.css'
import Pusher from "pusher-js";
import {GlobalWorkerOptions} from "pdfjs-dist";
import workerSrc from 'pdfjs-dist/build/pdf.worker.js';

const PDFJS = window.pdfjsLib;
GlobalWorkerOptions.workerSrc = workerSrc;

const drawerWidth = 300;
const NotaryTagging = () => {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jid = queryParams.get("jid");

    const [mainPdfJson, setMainPdfJson] = useState({});

    const [loading, setLoading] = useState(false);
    const [observer, setObserver] = useState(null);
    const [canvases, setCanvases] = useState([]);
    const [hasSelected, setHasSelected] = useState(false);
    const [activeTool, setActiveTool] = useState(null);

    const [jobSchedule, setJobSchedule] = useState({});
    const [documents, setDocuments] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [notary, setNotary] = useState({});
    const [sessionId, setSessionId] = useState(null);

    const [pageCount, setPageCount] = useState(0);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const [dialogComplete, setDialogComplete] = useState(false);
    const [pdfPages, setPdfPages] = useState([]);

    useEffect(() => {
        document.title = 'Notary Tagging';
        getDocuments()
    }, []);

    useEffect(() => {
        if(selectedDocument) {
            axios({
                method: 'post',
                url: `${baseUrl}/trigger-session/${sessionId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data : {
                    documentId : selectedDocument.ID,
                    event : 'docSelected'
                }
            }).then((res) => {

            });

            getPDFPages(selectedDocument.file_path)
        }
    }, [selectedDocument]);

    useEffect(() => {
        if (pdfPages) {
            let tempCanvases = [];
            pdfPages.forEach((page, index) => {
                const canvas = document.getElementById(`canvasPage_${page.page}`);
                const initCanvas = new fabric.Canvas(canvas);
                initCanvas.setHeight(page.height);
                initCanvas.setWidth(page.width);

                fabric.Image.fromObject({src: page.img}, function (img) {
                    initCanvas.setBackgroundImage(img, initCanvas.renderAll.bind(initCanvas), {
                        scaleX: initCanvas.width / img.width,
                        scaleY: initCanvas.height / img.height
                    });
                });

                if (mainPdfJson) {
                    renderObject(initCanvas, index, mainPdfJson);
                }

                window.addEventListener('keydown', (e) => {
                    if (e.key === 'Delete') {
                        const activeObject = initCanvas.getActiveObjects()
                        if (activeObject) {
                            activeObject.forEach((object) => {
                                initCanvas.remove(object);
                            });
                        }
                        initCanvas.discardActiveObject();
                    }
                });

                tempCanvases.push(initCanvas);
            });
            setCanvases(tempCanvases);
        }
    }, [pdfPages]);

    useEffect(() => {
        canvases && canvases.forEach((canvas) => {
            canvas.on('object:added', (e) => {
                getMetaData();
            });

            canvas.on('object:modified', (e) => {
                getMetaData();
            });

            canvas.on('object:removed', (e) => {
                getMetaData();
            });

            canvas.on('selection:created', () => {
                setHasSelected(true);
                const selectedObject = canvas.getActiveObject();
                if (selectedObject.metadata && selectedObject.metadata.id === 1) {
                    console.log('owner');
                }
            });

            canvas.on('selection:cleared', () => {
                setHasSelected(false);
            });
        });

        if (canvases) {
            try {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setCurrentPage(parseInt(entry.target.id.split('_')[1]));
                        }
                    });
                }, {threshold: 0.6});

                pdfPages.forEach((page, index) => {
                    observer.observe(document.getElementById(`observer_${page.page}`));
                });

                setObserver(observer);
            } catch (e) {
                console.log(e);
            }
        }
    }, [canvases]);

    useEffect(() => {
        if (selectedDocument) {
            window.addEventListener('keydown', (e) => {
                switch (e.key) {
                    case 't':
                        setTool('Notary Text', canvases, 'text', 'text');
                        break;
                    case 'a':
                        setTool('Notary Name', canvases, 'name', 'text');
                        break;
                    case 'n':
                        setTool('Commission ID', canvases, 'commission_id', 'text');
                        break;
                    case 'e':
                        setTool('Notary Seal', canvases, 'seal', 'placeholder');
                        break;
                    case 'o':
                        setTool('Notary Disclosure', canvases, 'disclosure','text');
                        break;
                    case 's':
                        setTool('Notary Signature', canvases, 'signature', 'placeholder', 50, 100);
                        break;
                    case 'x':
                        setTool('Commission Exp', canvases, 'commission_exp','text');
                        break;
                    case 'd':
                        setTool('Notary Date', canvases, 'date', 'text');
                        break;
                    case 'w':
                        setActiveTool('whiteout');
                        break;
                }
            });

            if (activeTool) {
                canvases.forEach((canvas) => {
                    canvas.on('mouse:down', (e) => {
                        switch (activeTool) {
                            case 'text':
                                addTextNotary(e.e, 'Notary Text', 'notary_text');
                                break;
                            case 'name':
                                addTextNotary(e.e, 'Notary Name', 'notary_name');
                                break;
                            case 'commission_id':
                                addTextNotary(e.e, 'Commission ID', 'notary_commission_id');
                                break;
                            case 'commission_exp':
                                addTextNotary(e.e, 'Commission Exp', 'notary_commission_exp');
                                break;
                            case 'seal':
                                addPlaceholderNotary(e.e, 'Notary Seal', 90, 90, 'notary_seal');
                                break;
                            case 'disclosure':
                                addTextNotary(e.e, 'Notary Disclosure', 'notary_disclosure');
                                break;
                            case 'signature':
                                addPlaceholderNotary(e.e, 'Notary Signature', 50, 100, 'notary_signature');
                                break;
                            case 'date':
                                addTextNotary(e.e, 'Notary Date', 'notary_date');
                                break;
                            case 'whiteout':
                                addWhiteOutNotary(e.e);
                                break;
                        }
                        setActiveTool(null);
                        canvas.defaultCursor = 'default';
                        canvas.off('mouse:down');
                    });
                });
            }
        }
    },[selectedDocument, canvases, activeTool]);

    const getDocuments = () => {
        axios({
            method: "get",
            url: `${baseUrl}/job-participants-docs/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
             setDocuments(res.data.job_docs);
             setParticipants(res.data.job_participants);
             setNotary(res.data.notary[0]);
             setSessionId(res.data.sessionData[0].id);
             setMainPdfJson(JSON.parse(res.data.sessionData[0].metadata));
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const updateMetaData = (metadata) => {
        axios({
            method: 'post',
            url: `${baseUrl}/update-meta-non-sync/${sessionId}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                metadata: JSON.stringify(metadata),
                from: 'notary',
            }
        }).then((res) => {
            console.log('res', res);
        });
    }


    const renderObject = (initCanvas, index, json) => {
        if (json.hasOwnProperty(`doc_${selectedDocument.ID}`)) {
            const objects = json[`doc_${selectedDocument.ID}`][index];
            objects && objects.forEach((object) => {
                fabric.util.enlivenObjects([object], (enlivenedObjects) => {
                    enlivenedObjects.forEach((obj) => {
                        initCanvas.add(obj);
                    });
                });
            });
        }
    }

    const getToolGhost = (text, type, height, width) => {
        let object = null;
        switch (type) {
            case 'text' :
                object = new fabric.Text(text, {
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    backgroundColor: '#f1c40f',
                });
                break;
            case 'placeholder' :
                const rect = new fabric.Rect({
                    height : height ?? 90,
                    width : width ?? 90,
                    fill: '#f1c40f',
                });
                const txt = new fabric.Text(text, {
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                });
                txt.left = rect.left + (rect.width - txt.width) / 2;
                txt.top = rect.top + (rect.height - txt.height) / 2;
                object = new fabric.Group([rect, txt], {
                    lockScalingX: true,
                    lockScalingY: true
                });
                break;
        }

        return object;
    }

    const setTool = (text, canvases, tool, type, height = null, width = null) => {
        canvases.forEach((canvas) => {
            canvas.defaultCursor = `url("${getToolGhost(text, type, height, width).toDataURL()}") 0 0, auto`;
        });
        setActiveTool(tool);
    }

    const getPDFPages = (url) => {
        destroyAllInstance();

        setLoading(true);
        setCurrentPage(1)
        setPdfPages([])
        window.scrollTo(0, 0);

        PDFJS.getDocument(url).promise.then((pdf) => {
            setPageCount(pdf.numPages);
            let tempPages = [];
            new Promise((resolve, reject) => {
                for (let i = 1; i <= pdf.numPages; i++) {
                    pdf.getPage(i).then(async (page) => {
                        const scale = 1;
                        const viewport = page.getViewport({scale: scale});
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };

                        await page.render(renderContext).promise;
                        let img = canvas.toDataURL('image/png');

                        tempPages.push({page: i, img: img, height : viewport.height, width : viewport.width});
                        tempPages.sort((a, b) => {
                            return a.page - b.page;
                        });

                        setTimeout(() => {
                            resolve(img)
                        }, 100)
                    });
                }
            }).then((response) => {
                setPdfPages(tempPages);
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const destroyAllInstance = () => {
        canvases && canvases.forEach((canvas) => {
            canvas.dispose();
        });

        observer && observer.disconnect();
    }

    const addText = (e, participant, text, type = null) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const textObject = new fabric.IText(text, {
            left: canvasPosition.x,
            top: canvasPosition.y,
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            backgroundColor: participant.tag_color + '80',
            autoWidth: true,
            lockRotation: true,
            lockScalingX: true,
            hasRotatingPoint: false,
            metadata : {
                type: type ? type : 'participant',
                id : participant.ID,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });


        canvas.add(textObject);
    }

    const addPlaceholder = (e, participant, text_description, height = 40, width = 150, type) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const rect = new fabric.Rect({
            height : height,
            width : width,
            fill: participant.tag_color + '80',
        });

        const text = new fabric.IText(text_description, {
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
        });

        text.left = rect.left + (rect.width - text.width) / 2;
        text.top = rect.top + (rect.height - text.height) / 2;

        const group = new fabric.Group([rect, text], {
            left: canvasPosition.x,
            top: canvasPosition.y,
            lockScalingX: true,
            lockScalingY: true,
            metadata: {
                type: type,
                id : participant.ID,
                status : 'pending',
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        canvas.add(group);
    }

    const addTextNotary = (e, text, tool) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const textObject = new fabric.IText(text, {
            left: canvasPosition.x,
            top: canvasPosition.y,
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            backgroundColor: '#f1c40f',
            autoWidth: true,
            lockRotation: true,
            lockScalingX: true,
            hasRotatingPoint: false,
            metadata: {
                type: 'notary',
                status : 'pending',
                tool : tool,
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        console.log(textObject);
        canvas.add(textObject);
    }

    const addPlaceholderNotary = (e, text_description, height = 40, width = 150, tool = null) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        const rect = new fabric.Rect({
            height : height,
            width : width,
            fill: '#f1c40f',
        });

        const text = new fabric.IText(text_description, {
            fill: 'black',
            fontSize: 14,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
        });

        text.left = rect.left + (rect.width - text.width) / 2;
        text.top = rect.top + (rect.height - text.height) / 2;

        const group = new fabric.Group([rect, text], {
            left: canvasPosition.x,
            top: canvasPosition.y,
            lockScalingX: true,
            lockScalingY: true,
            metadata: {
                type: 'notary',
                status : 'pending',
                tool : tool,
                uniqueId : Date.now().toString(36) + Math.random().toString(36).substr(2)
            }
        });

        canvas.add(group);
    }

    const addWhiteOutNotary = (e) => {
        e.preventDefault();
        const canvas = canvases[currentPage - 1];
        const canvasPosition = canvas.getPointer(e, false);

        //add only a resizable rectangle color white
        const rect = new fabric.Rect({
            left: canvasPosition.x,
            top: canvasPosition.y,
            fill: 'white',
            width: 100,
            height: 100,
            lockRotation: true,
            hasRotatingPoint: false,
        });

        canvas.add(rect);
    }

    function completeSession() {
        toast.success('Session Completed!');
        setIsCompleted(true);
        setDialogComplete(false);
    }

    function endSession() {
       const payload = {
           status: "END TAGGING",
           metadata: JSON.stringify(mainPdfJson),
           jobId: 1,
       };

       axios({
           method: "patch",
           url: `${baseUrl}/start-end/${id}`,
           data: payload,
           headers: {
               "Content-Type": "application/json",
           },
       }).then((res) => {
          if (res.status === 200) {
              window.location.href = `https://dctverify.com/job-view.php?jid=${encodeURIComponent(jid)}`;
          }
       }).catch((error) => {
           toast.error('Error Ending Session');
       });
    }

    const jumpToPage = (page) => {
        const canvas = document.getElementById(`canvasPage_${page}`);
        canvas.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    const getMetaData = async () => {
        let tempJson = {};
        await Promise.all(canvases.map((canvas, index) => {
            tempJson[index] = canvas.toJSON(
                ['type', 'left', 'top', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'backgroundColor', 'autoWidth', 'lockRotation', 'lockScalingX', 'hasRotatingPoint', 'metadata']
            ).objects;
        })).then(() => {
            if (!mainPdfJson) {
                let tempMainJson = {};
                documents.forEach((doc) => {
                    tempMainJson[`doc_${doc.ID}`] = [];
                })
                tempMainJson[`doc_${selectedDocument.ID}`] = tempJson;
                setMainPdfJson(tempMainJson);
                updateMetaData(tempMainJson);
            } else {
                let tempMainJson = mainPdfJson;
                tempMainJson[`doc_${selectedDocument.ID}`] = tempJson;
                setMainPdfJson(tempMainJson);
                updateMetaData(tempMainJson);
            }
        })
    }


    const sessionMenu = (
        <div>
            <Toolbar>
                <img
                    src={notaryLogo}
                    alt="image"
                    width="150"
                    height="32"
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                />
            </Toolbar>
            <Divider />

            <Grid container>
                <FormControl sx={{ m: 2 }} fullWidth size="small">
                    <InputLabel id="document-label">Select a Document</InputLabel>
                    <Select
                        labelId="document-label"
                        id="document-label"
                        label="Select a Document"
                        value={selectedDocument === null ? '' : selectedDocument}
                        onChange={(e) => setSelectedDocument(e.target.value)}
                    >
                        {documents.map((doc) => (
                            <MenuItem value={doc} key={doc.id}>
                                {doc.file_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </div>
    )

    const participantView = (
        <div>
            {participants.map((participant, index) => (
                <Box sx={{px : 2, pb : 2}} key={index}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <CircleIcon fontSize="small" sx={{ color: participant.tag_color, mr: 0.5 }}/>
                        <Typography variant="subtitle2">
                            {participant.fullname}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt : 2}}>
                        <Chip label="Text" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={(e) => addText(e, participant, 'Participant Text', 'participant_text')}/>
                        <Chip label="Name" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={(e) => addText(e, participant, 'Participant Name', 'participant_name')}/>
                        <Chip label="Sign" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={  (e) => addPlaceholder(e, participant, 'Participant Signature', 40, 150, 'participant_signature')}/>
                        <Chip label="Initial" draggable={true} size="small" variant="outlined"
                              sx={{ mr : 0.5}}
                              onDragEnd={  (e) => addPlaceholder(e, participant, 'Participant Initials', 80, 80, 'participant_initials')}/>
                    </Box>
                </Box>
            ))}
            <Divider/>
        </div>
    )

    const notaryView = (
        <div>
            <Dialog open={dialogComplete}
                    onClose={() => setDialogComplete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Complete Session"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to complete this session?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr : 3, pb : 3 }}>
                    <Button color="error" onClick={() => setDialogComplete(false)}>Cancel</Button>
                    <Button color="success" onClick={() => completeSession()}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{px : 2}}>
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <CircleIcon fontSize="small" sx={{ color: 'red', mr: 0.5 }}/>
                    <Typography variant="subtitle2">
                        {notary.fullname}
                    </Typography>
                </Box>
            </Box>
            <List component="nav" dense>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Text', 'notary_text')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'text' ? 'filled' : 'outlined' } size="small" label="T" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <Title />
                    </ListItemIcon>
                    <ListItemText primary="Text" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Name', 'notary_name')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'name' ? 'filled' : 'outlined' } size="small" label="A" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Name" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Title', 'notary_title')}>
                    <ListItemIcon>
                        <BusinessCenter/>
                    </ListItemIcon>
                    <ListItemText primary="Title" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Commission ID', 'notary_commission_id')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'commission_id' ? 'filled' : 'outlined' } size="small" label="N" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <Badge/>
                    </ListItemIcon>
                    <ListItemText primary="Commision ID"  />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Commission Exp', 'notary_commission_exp')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'commission_exp' ? 'filled' : 'outlined' } size="small" label="X" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <Event/>
                    </ListItemIcon>
                    <ListItemText primary="Commision Exp Date" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addPlaceholderNotary(e, 'Notary Seal', 90, 90, 'notary_seal')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'seal' ? 'filled' : 'outlined' } size="small" label="E" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <Verified/>
                    </ListItemIcon>
                    <ListItemText primary="Seal" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Disclosure', 'notary_disclosure')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'disclosure' ? 'filled' : 'outlined' } size="small" label="O" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <Notes/>
                    </ListItemIcon>
                    <ListItemText primary="Disclosure" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addPlaceholderNotary(e, 'Notary Signature' , 50, 100, 'notary_signature')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'signature' ? 'filled' : 'outlined' } size="small" label="S" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <Gesture/>
                    </ListItemIcon>
                    <ListItemText primary="Signature" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addPlaceholderNotary(e, 'Notary Initials', 70, 70, 'notary_initials')}>
                    <ListItemIcon>
                        <TextFieldsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Initial" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, 'Notary Date', 'notary_date')}
                          secondaryAction={
                              <Chip variant={ activeTool === 'date' ? 'filled' : 'outlined' } size="small" label="D" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <CalendarToday/>
                    </ListItemIcon>
                    <ListItemText primary="Date" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addTextNotary(e, '✓', 'notary_checkbox')}>
                    <ListItemIcon>
                        <CheckBox/>
                    </ListItemIcon>
                    <ListItemText primary="Checkbox" />
                </ListItem>
                <ListItem draggable={true} onDragEnd={(e) => addWhiteOutNotary(e)}
                          secondaryAction={
                              <Chip variant={ activeTool === 'whiteout' ? 'filled' : 'outlined' } size="small" label="W" sx={{borderRadius : 1}}/>
                          }>
                    <ListItemIcon>
                        <DriveFileRenameOutline/>
                    </ListItemIcon>
                    <ListItemText primary="White Out" />
                </ListItem>
            </List>
        </div>
    )

    const toolsMenu = (
        <div>
            <Box>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ m: 2 }}>
                    Participants
                </Typography>
            </Box>

            {participantView}

            <Box>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ m: 2 }}>
                    Notary
                </Typography>
            </Box>

            {notaryView}
        </div>
    )

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" elevation={0} sx={{
                width: { sm: `calc(100% - ${drawerWidth * (selectedDocument ? 2 : 1)}px)` },
                bgcolor: 'background.paper',
                ml: { sm: `${drawerWidth}px` },
                mr: { sm: `${selectedDocument ? drawerWidth : 0 }px` },
            }}>
                <Toolbar sx={{ justifyContent : "space-between" }}>
                    <Button variant="outlined" color="error" onClick={() => endSession()}>
                        End Session
                    </Button>

                    {
                        pageCount > 0 && (
                            <Pagination
                                count={pageCount}
                                onChange={(e, page) => jumpToPage(page)}
                                page={currentPage}
                                color="primary"
                                size="small"
                                shape="rounded"
                            />
                        )
                    }
                </Toolbar>
                <Divider/>
            </AppBar>


            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open>
                    {sessionMenu}
                </Drawer>
            </Box>


            {!selectedDocument && (
                <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : 1, bgcolor : '#f0f0f0', pl: {  sm: `${drawerWidth}px` },}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Select a document to start
                        </Typography>
                    </Box>
                </Box>
            )}

            {loading  && (
                <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : 2, bgcolor : '#f0f0f0'}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center'}}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Loading Document...
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : -10, bgcolor : '#f0f0f0'}}>
            </Box>

            <Box sx={{ pt : '100px', pb : '64px', width: '100%', flexGrow : 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor : '#f0f0f0'}}>
                <Grid container id="canvasPages">
                    {selectedDocument && (pdfPages.map((page, index) => (
                        //vertical scrolling only of pages
                        <Grid className="pages-container" id={`observer_${page.page}`} xs={12} key={index} sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', width : '100%', mb : 3}}>
                            <Chip key={index} label={`Page ${page.page}`} sx={{ mb : 2}} size="small"/>
                            <Card elevation={8} sx={{ borderRadius : 0 }}>
                                <canvas id={`canvasPage_${page.page}`} width={'720'} className="canvasObject"
                                        height={'932'}/>
                            </Card>
                        </Grid>
                    )))}
                </Grid>

                {isCompleted && (
                    <img src={imageComplete} alt="Complete Sign"
                         style={{
                             position: 'absolute',
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)',
                             width: 300,
                             height: 300
                         }}/>
                )}
            </Box>

            <Drawer sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
                    variant="persistent"
                    open={!(!selectedDocument)}
                    anchor="right"
            >
                {toolsMenu}
            </Drawer>
        </Box>
    );
}

export default NotaryTagging;
