import Grid from "@mui/material/Grid2";
import {fabric} from 'fabric';
import {
    AppBar,
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    InputLabel,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Pagination,
    Select,
    Toolbar,
    Typography
} from "@mui/material";
import notaryLogo from "../../Assets/images/notary_logo.png";
import React, {useEffect, useState} from "react";
import CircleIcon from '@mui/icons-material/Circle';
import ListItemIcon from "@mui/material/ListItemIcon";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PersonIcon from "@mui/icons-material/Person";
import {
    Badge,
    BusinessCenter,
    CalendarToday,
    CheckBox,
    Event,
    Gesture,
    Notes,
    Title,
    Verified
} from "@mui/icons-material";
import axios from "axios";
import {baseUrl} from "../../Utils/constant";
import {useParams} from "react-router-dom";
import {FaEraser} from "react-icons/fa";
import imageComplete from '../../Assets/images/completed-sign.png';
import {toast} from 'react-toastify';
import '../../App.css'
import Pusher from "pusher-js";
import {GlobalWorkerOptions} from "pdfjs-dist";
import workerSrc from 'pdfjs-dist/build/pdf.worker.js';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const PDFJS = window.pdfjsLib;
GlobalWorkerOptions.workerSrc = workerSrc;

const drawerWidth = 300;

const NotarySigner = () => {
    const { id } = useParams();

    const [mainPdfJson, setMainPdfJson] = useState({});

    const [loading, setLoading] = useState(false);
    const [observer, setObserver] = useState(null);
    const [canvases, setCanvases] = useState([]);
    const [hasSelected, setHasSelected] = useState(false);

    const [jobSchedule, setJobSchedule] = useState({});
    const [documents, setDocuments] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [participant, setParticipant] = useState(null);
    const [sessionId, setSessionId] = useState(null);

    const [pageCount, setPageCount] = useState(0);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const [pdfPages, setPdfPages] = useState([]);
    const [dialogEnd, setDialogEnd] = useState(false);
    const [textEditing, setTextEditing] = useState(false);

    useEffect(() => {
        document.title = 'Notary Signer';
        getSession()
    }, []);

    useEffect(() => {
        if(selectedDocument) {
            getPDFPages(selectedDocument.file_path)
        }
    }, [selectedDocument]);

    useEffect(() => {
        if (pdfPages) {
            let tempCanvases = [];
            pdfPages.forEach((page, index) => {
                const canvas = document.getElementById(`canvasPage_${page.page}`);
                const initCanvas = new fabric.Canvas(canvas);
                initCanvas.setWidth(page.width);
                initCanvas.setHeight(page.height);

                fabric.Image.fromObject({src: page.img}, function (img) {
                    initCanvas.setBackgroundImage(img, initCanvas.renderAll.bind(initCanvas), {
                        scaleX: initCanvas.width / img.width,
                        scaleY: initCanvas.height / img.height
                    });
                });

                initCanvas.selection = false;


                if (mainPdfJson) {
                    renderObject(initCanvas, index, mainPdfJson);
                }

                tempCanvases.push(initCanvas);
            });
            setCanvases(tempCanvases);
        }
    }, [pdfPages]);

    useEffect(() => {
        if (canvases) {
            canvases && canvases.forEach((canvas) => {
                canvas.off('selection:created');
                canvas.off('selection:cleared');
                canvas.off('selection:updated');

                canvas.on('selection:created', () => {
                    setHasSelected(true);
                    const selectedObject = canvas.getActiveObject();
                    if (selectedObject.metadata.status !== 'signed') {
                        if (selectedObject.metadata && selectedObject.metadata.id === participant.ID) {
                            objectReplacer(canvas, selectedObject);
                        }
                    }
                });

                //updated
                canvas.on('selection:cleared', () => {
                    setHasSelected(false);
                });

                canvas.on('selection:updated', () => {
                    const selectedObject = canvas.getActiveObject();
                    if (selectedObject.metadata.status !== 'signed') {
                        if (selectedObject.metadata && selectedObject.metadata.id === participant.ID) {
                            objectReplacer(canvas, selectedObject);
                        }
                    }
                });
            });

            try {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setCurrentPage(parseInt(entry.target.id.split('_')[1]));
                        }
                    });
                }, {threshold: 0.6});

                pdfPages.forEach((page, index) => {
                    observer.observe(document.getElementById(`observer_${page.page}`));
                });

                setObserver(observer);
            } catch (e) {
                console.log(e);
            }
        }

        return () => {
            observer && observer.disconnect();
            canvases && canvases.forEach((canvas) => {
                canvas.dispose();
            })
        }
    }, [canvases]);

    useEffect(() => {
        if (sessionId) {
            const pusher = new Pusher('8832ca315ec10869b1e6', {
                cluster: 'ap1'
            });

            let channel = pusher.subscribe(`notary-session-${sessionId}`);
            console.log(`notary-session-${sessionId}`)
            channel.bind('updateNotaryMetadata', function(data) {
                axios({
                    method: 'get',
                    url: `${baseUrl}/findSessionLink/${id}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    setMainPdfJson(JSON.parse(res.data.sessions[0].metadata));
                    const metadata = JSON.parse(res.data.sessions[0].metadata);
                    if (metadata.hasOwnProperty(`doc_${selectedDocument.ID}`)) {
                        let currDocMeta = metadata[`doc_${selectedDocument.ID}`];
                        canvases.forEach((canvas, index) => {
                            const tempMetaData = canvas.toJSON(
                                ['type', 'left', 'top', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'backgroundColor', 'autoWidth', 'lockRotation', 'lockScalingX', 'hasRotatingPoint', 'metadata']
                            );
                            tempMetaData.objects = currDocMeta[index];
                            canvas.loadFromJSON(tempMetaData, () => {
                                canvas.renderAll();
                            });
                        });
                    }
                });
            });

            channel.bind('syncPage', function (data) {
                if (data) {
                    const selDoc = documents.find((doc) => doc.jobDocument.ID === data.documentId);
                    setSelectedDocument(selDoc.jobDocument)
                }
            });

            channel.bind('endNotarization', function (data) {
                setDialogEnd(true);
                console.log('end notarization', data);
            });

            return () => {
                pusher.unsubscribe(`notary-session-${sessionId}`);
            }
        }
    }, [sessionId, selectedDocument, canvases]);

    const getSession = () => {
        axios({
            method: 'get',
            url: `${baseUrl}/findSessionLink/${id}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setDocuments(res.data.sessionDocs);
            setJobSchedule(res.data.jobSchedule);
            setParticipants(res.data.jobParticipants);
            setParticipant(res.data.jobParticipants[0])
            setSessionId(res.data.sessions[0].id);
            setMainPdfJson(JSON.parse(res.data.sessions[0].metadata));
        });
    }

    const updateMetaData = (mainPdfJson) => {
       axios({
            method: 'post',
            url: `${baseUrl}/update-signer-metadata/${sessionId}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                metadata: JSON.stringify(mainPdfJson),
                from : 'signer'
            }
        }).then((res) => {
            console.log('res', res);
        });
    }

    const renderObject = (initCanvas, index, json) => {
        if (json.hasOwnProperty(`doc_${selectedDocument.ID}`)) {
            // const objects = json[`doc_${selectedDocument.ID}`][index];
            // let tempCanvasJSON = initCanvas.toJSON(
            //     ['type', 'left', 'top', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'backgroundColor', 'autoWidth', 'lockRotation', 'lockScalingX', 'hasRotatingPoint', 'metadata']
            // );
            // tempCanvasJSON.objects = objects;
            // initCanvas.loadFromJSON(tempCanvasJSON);
            const objects = json[`doc_${selectedDocument.ID}`][index];
            objects && objects.forEach((object) => {
                fabric.util.enlivenObjects([object], (enlivenedObjects) => {
                    enlivenedObjects.forEach((obj) => {
                        initCanvas.add(obj);
                    });
                });
            });
        }
    }


    const objectReplacer = (canvas, object) => {
        switch (object.metadata.type) {
            case 'participant_text' :
                if (object.text === 'Participant Text') {
                    object.text = '';
                    object.backgroundColor = '';
                    object.enterEditing();
                    setTextEditing(true);
                    object.on('editing:exited', (e) => {
                        new Promise((resolve, reject) => {
                            setTextEditing(false);
                            if (object.text === '') {
                                canvas.remove(object);
                            }
                            object.metadata = {
                                type: 'participant',
                                status : 'signed',
                                id: participant.ID,
                            }
                            resolve();
                        }).then(() => {
                            getMetaData();
                        });
                    })
                }
                break;
            case 'participant_name':
                const text = new fabric.IText(participant.fullname, {
                    left: object.left,
                    top: object.top,
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    autoWidth: true,
                    selectable: false,
                    hasRotatingPoint: false,
                    metadata: {
                        type: 'participant',
                        id: participant.ID,
                        status : 'signed'
                    }
                })
                canvas.remove(object);
                canvas.add(text);
                getMetaData();
                break;
            case 'participant_initials':
                const img = new Image();
                img.src = participant.initials_filename;
                img.onload = function () {
                    const fabricImg = new fabric.Image(img, {
                        left: object.left,
                        top: object.top,
                        selectable: false,
                        hasRotatingPoint: false,
                        metadata: {
                            type: 'participant',
                            id: participant.ID,
                            status : 'signed'
                        }
                    });
                    fabricImg.scaleToWidth(50)
                    canvas.remove(object);
                    canvas.add(fabricImg);
                    getMetaData();
                }
                break;
            case 'participant_signature':
                const img2 = new Image();
                img2.src = participant.signature_filename;
                img2.onload = function () {
                    const fabricImg2 = new fabric.Image(img2, {
                        left: object.left,
                        top: object.top,
                        hasRotatingPoint: false,
                        selectable: false,
                        metadata: {
                            type: 'participant',
                            id: participant.ID,
                            status : 'signed'
                        }
                    });

                    fabricImg2.scaleToWidth(100)
                    canvas.remove(object);
                    canvas.add(fabricImg2);
                    getMetaData();
                }
                break;
        }
    }

    const getPDFPages = (url) => {
        destroyAllInstance();
        setLoading(true);
        setCurrentPage(1)
        setPdfPages([])
        window.scrollTo(0, 0);

        setTimeout(() => {
            PDFJS.getDocument(url).promise.then((pdf) => {
                setPageCount(pdf.numPages);
                let tempPages = [];
                new Promise((resolve, reject) => {
                    for (let i = 1; i <= pdf.numPages; i++) {
                        pdf.getPage(i).then(async (page) => {
                            const scale = 1;
                            const viewport = page.getViewport({scale: scale});
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d');
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            const renderContext = {
                                canvasContext: context,
                                viewport: viewport
                            };

                            await page.render(renderContext).promise;
                            let img = canvas.toDataURL('image/png');

                            tempPages.push({page: i, img: img, height : viewport.height, width : viewport.width});
                            tempPages.sort((a, b) => {
                                return a.page - b.page;
                            });

                            setTimeout(() => {
                                resolve(img)
                            }, 100)
                        });
                    }
                }).then((response) => {
                    setPdfPages(tempPages);
                    setLoading(false);
                });
            });
        }, 500);
    }

    const destroyAllInstance = () => {
        canvases && canvases.forEach((canvas) => {
            canvas.dispose();
        });

        observer && observer.disconnect();
    }


    const jumpToPage = (page) => {
        const canvas = document.getElementById(`canvasPage_${page}`);
        canvas.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    const getMetaData = () => {
        let tempJson = {};
        canvases.forEach((canvas, index) => {
            tempJson[index] = canvas.toJSON(
                ['type', 'left', 'top', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'backgroundColor', 'autoWidth', 'lockRotation', 'lockScalingX', 'hasRotatingPoint', 'metadata']
            ).objects;
        });

        if (!mainPdfJson) {
            let tempMainJson = {};
            documents.forEach((doc) => {
                tempMainJson[`doc_${doc.jobDocument.ID}`] = [];
            })
            tempMainJson[`doc_${selectedDocument.ID}`] = tempJson;
            setMainPdfJson(tempMainJson);
            updateMetaData(tempMainJson);
        } else {
            let tempMainJson = mainPdfJson;
            tempMainJson[`doc_${selectedDocument.ID}`] = tempJson;
            setMainPdfJson(tempMainJson);
            updateMetaData(tempMainJson);
        }
    }


    const sessionMenu = (
        <div>
            <Toolbar>
                <img
                    src={notaryLogo}
                    alt="image"
                    width="150"
                    height="32"
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                />
            </Toolbar>
            <Divider />

            <Grid container>
                <FormControl sx={{ m: 2 }} fullWidth size="small">
                    <InputLabel id="document-label">Select a Document</InputLabel>
                    <Select
                        labelId="document-label"
                        id="document-label"
                        label="Select a Document"
                        value={selectedDocument === null ? '' : selectedDocument}
                        onChange={(e) => setSelectedDocument(e.target.value)}
                    >
                        {documents.map((doc) => (
                            <MenuItem value={doc.jobDocument} key={doc.id}>
                                {doc.jobDocument.file_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ p : 2 }}>
                    {jobSchedule.length ? (
                        jobSchedule.map((schedule, index) => (
                            <div key={index}>
                                <iframe
                                    src={schedule.whereby_host_link + '&logo=off&background=off'}
                                    title="Whereby Session"
                                    width="100%"
                                    height="550px"  // Adjust this according to your sidebar height
                                    style={{ border: 'none', borderRadius: '10px' }}
                                    allow="camera; microphone; fullscreen"
                                />
                            </div>
                        ))
                    ) : (
                        <p>Loading video...</p>
                    )}
                </Box>
            </Grid>
        </div>
    )

    const participantView = (
        <div>
            {participants.map((participant, index) => (
                <Box sx={{px : 2, pb : 2}} key={index}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <CircleIcon fontSize="small" sx={{ color: participant.tag_color, mr: 0.5 }}/>
                        <Typography variant="subtitle2">
                            {participant.fullname}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt : 2}}>
                        <Chip label="Text" size="small" variant="outlined"
                              sx={{ mr : 0.5}}/>
                        <Chip label="Name" size="small" variant="outlined"
                              sx={{ mr : 0.5}}/>
                        <Chip label="Sign" size="small" variant="outlined"
                              sx={{ mr : 0.5}}/>
                        <Chip label="Initial" size="small" variant="outlined"
                              sx={{ mr : 0.5}}/>
                    </Box>
                </Box>
            ))}
            <Divider/>
        </div>
    )

    const toolsMenu = (
        <div>
            <Box>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ m: 2 }}>
                    Participants
                </Typography>
            </Box>

            {participantView}
        </div>
    )

    return (
        <Box sx={{ display: 'flex' }}>
            <Dialog open={dialogEnd}
                    aria-labelledby="alert-dialog-title"
                    disableEscapeKeyDown={true}
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                    {"Session Ended"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{ display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                            <ErrorTwoToneIcon color="error" sx={{ fontSize: 100 }} />
                        </Box>
                    </DialogContentText>
                    <DialogContentText sx={{ textAlign : 'center', mt : 3}}>
                        Your session has ended. <br/> Please contact the notary for further information.
                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <CssBaseline />
            <AppBar position="fixed" elevation={0} sx={{
                width: { sm: `calc(100% - ${drawerWidth * (selectedDocument ? 2 : 1)}px)` },
                bgcolor: 'background.paper',
                ml: { sm: `${drawerWidth}px` },
                mr: { sm: `${selectedDocument ? drawerWidth : 0 }px` },
            }}>
                <Toolbar sx={{ justifyContent : "center" }}>
                    {
                        pageCount > 0 && (
                            <Pagination
                                count={pageCount}
                                onChange={(e, page) => jumpToPage(page)}
                                page={currentPage}
                                color="primary"
                                size="small"
                                shape="rounded"
                            />
                        )
                    }
                </Toolbar>
                <Divider/>
            </AppBar>


            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open>
                    {sessionMenu}
                </Drawer>
            </Box>


            {!selectedDocument && (
                <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : 1, bgcolor : '#f0f0f0', pl: {  sm: `${drawerWidth}px` },}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Select a document to start
                        </Typography>
                    </Box>
                </Box>
            )}

            {loading  && (
                <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : 2, bgcolor : '#f0f0f0'}}>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}>
                        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center'}}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Loading Document...
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box sx={{ position : 'absolute', width : '100%', height : '100%', zIndex : -10, bgcolor : '#f0f0f0'}}>
            </Box>

            <Box sx={{ pt : '100px', pb : '64px', width: '100%', flexGrow : 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor : '#f0f0f0'}}>
                <Grid container id="canvasPages">
                    {selectedDocument && (pdfPages.map((page, index) => (
                        //vertical scrolling only of pages
                        <Grid className="pages-container" id={`observer_${page.page}`} xs={12} key={index} sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', width : '100%', mb : 3}}>
                            <Chip key={index} label={`Page ${page.page}`} sx={{ mb : 2}} size="small"/>
                            <Card elevation={8} sx={{ borderRadius : 0 }}>
                                <canvas id={`canvasPage_${page.page}`} width={'720'} className="canvasObject"
                                        height={'932'}/>
                            </Card>
                        </Grid>
                    )))}
                </Grid>

                {isCompleted && (
                    <img src={imageComplete} alt="Complete Sign"
                         style={{
                             position: 'absolute',
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)',
                             width: 300,
                             height: 300
                         }}/>
                )}
            </Box>

            <Drawer sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
                    variant="persistent"
                    open={!(!selectedDocument)}
                    anchor="right"
            >
                {toolsMenu}
            </Drawer>
        </Box>
    );
}

export default NotarySigner;
